
import React from 'react'
import ApeBody from '../../components/ApeBody'

import StatsPanel from "../../components/StatsPanel"
import ApeDetail from "../../components/ApeDetail/"


const ApePage = () =>{
  return(     
    <>
    
    <div className="row">
      <div className="col-md-6 pt-5">
      <ApeBody ape={false} showOverlay={true}/>
      </div>
      <div className="col-md-6">
        <ApeDetail ape={false} />
        <div className="apeHelp_text">
        <h3 className="heading-2 py-3"><img src="/assets/images/red.png" className="img-fluid" /> 1,203 Users Making Apes</h3>
       
        </div>
        <h4 className="text-center">Secure Your Ape While You Can</h4>
      </div>
    </div>
    </>
  )
}


export default ApePage
import React,{useState,useEffect} from 'react';
import ApeBody from '../../components/ApeBody'
import ApeBlock from '../../components/ApeBody/ApeBlock'

import {Link,withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {resetBuildApe,apeBuy} from "../../actions"

import StatsPanel from "../../components/StatsPanel"
import ApeDetail from "../../components/ApeDetail/"
import DetailBlock from "../../components/ApeDetail/DetailBlock"
import * as constants from "../../constants"
import {baseImageApe} from "../../utlis"

const ApePageBuy = (props) =>{
  const {user,buildApeObject} = props;
  const [buyProcess,setBuyProcess] = useState(false);
  const [showOverlay,setOverlay] = useState(true);
  const [buyApeObject,setBuyApeObject] = useState(buildApeObject);
  const currentApeContract = props.location.query.currentApeContract;


  let ape = buyApeObject.data;

  let sellSuccess = (apeObj,isAlpha,data)=>{
    data={eth_address:user.user.eth_address,is_alpha:isAlpha,user_id:user.user.id,ape_id:apeObj.id,tx_hash:data.transactionHash,contract_address:currentApeContract}
    props.apeBuy(data);
    
    buyApeObject.data.is_alpha = isAlpha;
    ape = buyApeObject.data;
    setBuyApeObject(buyApeObject);
    props.resetBuildApe();

    setBuyProcess(true);
    
    constants.GPageEvent("Sell Success","Ape Sold",isAlpha? "Alpha-Sale" : "Normal-Sale");

  }

  let sellError = ()=>{
      props.resetBuildApe();
      
  }

  useEffect(()=>{
    constants.GPageView("Buy Page","/ape_buy");
    constants.GScreenView("Ape Buy Screen");
  },[])

  // for updating image only
    useEffect(()=>{
      if(buyApeObject.data!=false && buyApeObject.data.image ==baseImageApe() && buildApeObject.data){ 
        const bdata = {...buyApeObject.data} 
        bdata.image = buildApeObject.data.image;
        ape = bdata;
        buyApeObject.data = bdata
        setBuyApeObject(buyApeObject);
      }
    },[buildApeObject.data.image])

  let objectShow = (apeObj)=>{
    return {
        "description": "An ape from the Polyient Games Ape Island Series.", 
        "external_url": "", 
        "image": apeObj.picture, 
        "name": apeObj.name,
        "id": apeObj.id,
        "attributes": [
           
          {
            "trait_type": "Attack", 
            "value": apeObj.state_attack
          }, 
          {
            "trait_type": "Defense", 
            "value": apeObj.state_defense
          }, 
          {
            "trait_type": "Speed", 
            "value": apeObj.state_speed
          },
          {
            "trait_type": "Ability", 
            "value": apeObj.state_ability
          }, 
          {
            "trait_type": "Nature", 
            "value": apeObj.state_nature
          }
        ]
      }
    }


const shareTwitter = (app)=>{
  let name = constants.APE_TOKEN_SEASON1_ADDR == currentApeContract ? "s1" : "s2";
  if(constants.APE_TOKEN_SEASON3_ADDR == currentApeContract){
    name='s3';
  }
  if(constants.APE_BREED_ADDR == currentApeContract){
    name='mate';
  }

  window.open("https://twitter.com/share?text=Check out my new friend on Ape Island! Where fun meets NFTs and DeFi.&url="+constants.DOMAIN_NAME+"gallery/"+name+"/"+app.id+"&hashtags=ApeIn,ApeIsland");
  constants.GPageEvent("Social","Twitter-Share","Share");
}

  return(
    <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-6 pt-5">
       <ApeBody ape={buyApeObject.data}  currentApeContract={currentApeContract} blockType={buyProcess ? "sold" :"sell"}></ApeBody>
      </div>
      {!buyProcess &&
        <div className="col-md-6">
            <ApeDetail sellSuccess={sellSuccess} sellError={sellError} currentApeContract={currentApeContract}/>
        </div>
      }
        
    </div>
    {buyProcess &&
      <>
      <div style={{height: '700px'}}></div>
      <div className="mainOnBoarding">
          <div className="wooden_pole"><h2 className="">Congrats!!</h2></div>

          <div className="detail_section">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-4">
                <div className="content">You've added one great looking Ape to your crew! Congrats!</div>
              </div>
              <div className="col-md-6">
                <button className="btn-social-gem" onClick={()=>{shareTwitter(buyApeObject.data)}} style={{paddingTop: '10px',fontSize: '1.35rem', lineHeight: "2em",width:"200px"}}>Share</button>
              
                <Link to="/gallery" className="btn btn-connect py-3 font-18">View In Gallery</Link>
              </div>

              <div className="col-md-2">
                <div className="img_ch"><img src="/assets/new_images/ape_char_new.png" className="img-fluid" /></div>
              </div>
            </div>
          </div>
        </div>
      </>
    }
    </div>

  )
}

const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  buildApeObject: (state) => state.buildApe,
  apes: (state) => state.apes,
})
const mapDisactcProps = { resetBuildApe,apeBuy };

export default connect(structuredSelector,mapDisactcProps)(ApePageBuy)
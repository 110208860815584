import React from "react";
import NavBar from "../components/NavBar"

const LayoutOne = ({ children }) => <div className="on-board" style={{backgroundImage: "url('/assets/new_images/onboarding-img-3.png')"}}>{children}</div>;
const LayoutLogin = ({ children }) => <div className="on-board-login">{children}</div>;

const LayoutTwo = (props) => (
  <div className="landing">
    <div className="container">
      <NavBar {...props} />
      {props.children}
    </div>
  </div>
);

const LayoutThree = (props) => (
  <div className="apemaker">
    <div className="container">
      <NavBar {...props}/>
      {props.children}
    </div>
  </div>
);

export { LayoutOne, LayoutTwo,LayoutThree, LayoutLogin };

export * from "./base";
import { fetchReq, postReq, putReq, deleteReq } from './base';

export function getUserDetails(address) {
	return fetchReq('/api/v1/user/details/'+address,'GET_USER_DETAILS');
};

export function getUserApes(address) {
	return fetchReq('/api/v1/apes/'+address,'GET_USER_APES');
};

export function findUserApe(address,id,contract_address) {
	return fetchReq('/api/v1/apes/show/'+id+'/'+address+'?contract_address='+contract_address,'GET_APES');
};
  
export function buildApe(data) {
	return postReq('/api/v1/apes/build',data, 'BUILD_USER_APES');
};

export function apeBuy(data) {
	return postReq('/api/v1/user/ape_buy',data, 'BUY_USER_APES');
};


export function checkApe(app_id,data, callback) {
	return (dispatch)=>{
		 postReq('/api/v1/apes/chk/'+app_id, data,'POST_CHK_APE')((data) => { 
			if (data.type == 'POST_CHK_APE_SUCCESS') {
				callback(data.json.data);
			}
			dispatch(data)
		})
	}
}


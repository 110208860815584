import React from "react"


const RewardFrame = (props) =>{
  return(
    <div className="reward-frame test" style={{padding:'1rem 4.5 rem !important'}}>
      {props.voucher.total == null ?
          <span className="info-warning"><i className="fa fa-info"></i></span>
      :
        <>
        {props.voucher.total > 0 ?
        <span className="info-success">{props.voucher.total}</span>
        :
        <span className="info-danger"><i className="fas fa-times"></i></span> 
        }
        </>
      } 
      <img src={props.voucher.image} className="galleryImage"/>
    </div>
  )
}



export default RewardFrame
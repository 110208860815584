import React from 'react'

import Modal from 'react-bootstrap/Modal'
const AlertModal = (props) => {

  // const { user, apes, setApe } = props;
  let apeClose = (ap) => {
    props.handleCloseAlert(ap);
  }

  return (
    <>
      <Modal  show={props.show} onHide={props.handleCloseAlert} backdrop={true} backdropClassName="modal-backdrop foo-modal-backdrop in" dialogClassName="modal-alert">
        <Modal.Header closeButton>
          <Modal.Title><h2 className="heading-3 mb-0 text-center" style={{fontSize:"30px"}}>Alert</h2> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <h4 className="" dangerouslySetInnerHTML={{__html:props.message}} >
            </h4>
          </div>

        </Modal.Body>
      
      </Modal>
    </>
  )
}

export default AlertModal

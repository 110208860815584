import React,{useState,useEffect} from 'react'

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";


import {getTimeLeftCount,getAllBreedTokens,updateBreedToken} from "../../actions/connector"

const GeneratedApe = (props) => {
  const {ape} = props;
  const [bornTimeLeft, setBornTimeLeft] = useState(null);


  useEffect(async()=>{
    window.getTimeLeftCount = getTimeLeftCount;
    let time = await getTimeLeftCount(ape.id);
    time = parseInt(time/60)
    setBornTimeLeft(time);
    let interval = null;
    if(time!="0"){
      interval = setInterval(async()=>{
        let time1 = await getTimeLeftCount(ape.id);
          if(time1 == "0"){
            clearInterval(interval);
            props.updateBreedToken(ape.id);
          }

        time1 = parseInt(time1/60)
        setBornTimeLeft(time1);
        
      },1000);
    }else{
      if(interval != null){
        clearInterval(interval);
      }
    }
  return ()=>{
    if(interval != null){
      clearInterval(interval);
    }
  }


  },[])
  

  const getValue =(type)=>{
    let value = '';
    ape.attributes.map((v,i)=>{
      if(v.trait_type == type){
        value = v.value;
      }
    })
    return value;
  }

  const openLink = ()=>{
    window.open(ape.external_url);
  }

  return (
    <>
      <div className="card breed_ape_border-0 pb-5">
        <div className="card-body d-flex generated_ape_card_body align-items-center row">
          <div className="reward-box ape_box col-3">
            <div className="pt-3 reward-frame test text-center">
              <img src={ape.image} className="" width="200" onClick={openLink} style={{cursor:"pointer"}}/>
            </div>

          </div>
          <div className="pl-5 breed_details col-7">
            <h4>Name: <span className="">{ape.name}</span></h4>
            {bornTimeLeft > 0 &&
            <h4>Time Left: <span className="">{bornTimeLeft}Min</span> </h4>
            }    
            <h4>Ancestor 1: <span className="">{getValue('Ancestor 1')}</span> </h4>
            <h4>Ancestor 2: <span className="">{getValue('Ancestor 2')}</span> </h4>
      
          </div>
        
          <a href={ape.external_url} className="px-4 breed_details d-flex col-2 align-items-center" target="_blank" style={{textDecoration:"none"}}> <h4 style={{fontSize:"1.7rem"}}>View <img src="/assets/images/blue_next.png" width="40" /></h4> </a>
        </div>
      </div>

    </>
  )
}


// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
});
const mapDisactcProps = {getAllBreedTokens,updateBreedToken};
export default connect(structuredSelector, mapDisactcProps)(GeneratedApe);


import React,{useEffect} from 'react'

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getApeNFTByUser } from "../../../actions";

import {APE_TOKEN_SEASON1_ADDR,APE_TOKEN_SEASON2_ADDR} from "../../../constants"


import Modal from 'react-bootstrap/Modal'
import BreedApeImageBox from '../../BreedApeImageBox';
const SelectApeModal = (props) => {
  const { user, apeNfts,seasonAddr } = props;

  useEffect(()=>{
    if(seasonAddr==APE_TOKEN_SEASON2_ADDR){
      props.getApeNFTByUser(user.user,APE_TOKEN_SEASON2_ADDR);
    }else{
      props.getApeNFTByUser(user.user,APE_TOKEN_SEASON1_ADDR);
    }
  },[])

  let appGallery =   apeNfts[seasonAddr]; 
  if(typeof appGallery =='undefined'){
    appGallery={}
    appGallery.type= "";
    appGallery.totalToken= null;
    appGallery.data= [];
  }

  let apeClose=(ap)=>{
    props.handleClose(ap);
  }

  let galleryImages = appGallery.data.map((v) => {
    if(v.status == "404"){
      return null
    }
    return (
        <div className="col-md-3 mt-3" key={v.id}>
          <BreedApeImageBox ape={v} setApe={()=>{apeClose(v)}} seasonAddr={seasonAddr}/>
        </div>
    );
  });




  return (
    <Modal size="xl" show={props.show} onHide={props.handleClose} backdrop={true} backdropClassName="modal-backdrop foo-modal-backdrop in" dialogClassName="modal-90w">
      <Modal.Header closeButton>
        <Modal.Title><h2 className="heading-4 mb-0 text-center"> Choose Your Ape From - {seasonAddr==APE_TOKEN_SEASON1_ADDR ? "Season1" : "Season2"} </h2> </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="row">
          {appGallery.type == 'new_request' &&
            <h4 className="text-center">Loading...</h4>
          }
        {(appGallery.type == 'success' && appGallery.data.length == 0) &&
            <>{seasonAddr==APE_TOKEN_SEASON1_ADDR ? 
              <>
                <div className="col-md-12 text-center"><br/><br/><br/><h2>Arg! Looks like you couldn't find any crew mates for Season 1. Click below to see if any are willing and able to join yer crew.</h2>
                <br/><br/>
                <div className="mt-3">
                
                  <p className="text-font-primary">  <a href="https://opensea.io/collection/ape-island-season-1" target="_blank" className="text-font-primary text-center" style={{textDecoration:"none"}}><img src="/assets/images/round-cart.png" className="img-fluid" /> Buy Now </a></p>
                
                </div>
                </div>
              </>
              : 
              <>
                <div className="col-md-12 text-center"><br/><br/><br/><h2>Arg! Looks like you couldn't find any crew mates for Season 2. Click below to see if any are willing and able to join yer crew.</h2>
                <br/><br/>
                <div className="mt-3">
                
                  <p className="text-font-primary">  <a href="https://opensea.io/collection/ape-island-season-2" target="_blank" className="text-font-primary text-center" style={{textDecoration:"none"}}><img src="/assets/images/round-cart.png" className="img-fluid" /> Buy Now </a></p>
                
                </div>
                </div>
              </>
              
              }</>
          }

          {galleryImages}
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="primary" > Save </Button>
      </Modal.Footer> */}
    </Modal>

  )
}

const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  apeNfts: (state) => state.apeNfts,
});
const mapDisactcProps = { getApeNFTByUser };
export default connect(structuredSelector, mapDisactcProps)(SelectApeModal);

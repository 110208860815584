import React, { useState,useEffect} from 'react'
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import GeneratedApe from '../../components/BreedApeImageBox/GeneratedApe';
import SelectApeModal from "../../components/ModalBox/SelectApeModal";

import * as constants from "../../constants";

import {getBreedCount,breedApe,getApeNFTByUser,getApeMatePrice} from "../../actions/connector"
import AlertModal from '../../components/ModalBox/AlertModal';
import ChooseSeason from '../../components/ModalBox/ChooseSeason';

const Breeding = (props) => {
  const [apeTokenPrice, setApeTokenPrice] = useState(0);
  const [pageView, setPageView] = useState(false);
  const [show, setShow] = useState(false);
  const [parentFlag, setParentFlag] = useState(0);
  const [isBreedStart, setBreedStart] = useState(0);
  const [parent, setParent] = useState({father:null,father_addr:null,father_count:null,mother:null,mother_addr:null,mother_count:null});

  const [showChooseSeasonModal, setShowChooseSeasonModal] = useState(false);
  const [seasonAddressSelected, setSeasonAddressSelected] = useState("");

  const [showError,SetShowError] = useState("");

  let breedApes = props.apeNfts[constants.APE_BREED_ADDR];
  if(typeof breedApes =='undefined'){
    breedApes={}
    breedApes.type= "";
    breedApes.totalToken= null;
    breedApes.data= [];
  }

  useEffect(async()=>{
    props.getApeNFTByUser(props.user.user,constants.APE_BREED_ADDR);
  
  },[]);
  
  const handleCloseSeasonModal = () =>{
    setShowChooseSeasonModal(false)
  }

  const seasonSelect=(addr)=>{
    setSeasonAddressSelected(addr);
    handleShow();
  }


  const handleClose = async (ape=null) => {
    if(ape!=null){
      let p ={...parent};
        if(parentFlag == 1){
          p.father = ape;
          p.father_addr = seasonAddressSelected;
          p.father_count = await getBreedCount(ape.id,seasonAddressSelected);
          if(p.father_count>=3){
            SetShowError("Max ape mating count reached! Your ape is now eligible for the Elder Ape upgrade. Read more <a href='https://docs.ape.in/apein-token/elder-apes' target='_blank'>here</a>.")
            return
          }
        }else{
          p.mother = ape;
          p.mother_addr = seasonAddressSelected;
          p.mother_count = await getBreedCount(ape.id,seasonAddressSelected);
          if(p.mother_count>=3){
            SetShowError("Max ape mating count reached! Your ape is now eligible for the Elder Ape upgrade. Read more <a href='https://docs.ape.in/apein-token/elder-apes' target='_blank'>here</a>.");
            return
          }
        }
       try{
        if(p.father.id ==  p.mother.id &&  p.mother_addr == p.father_addr){
          SetShowError("You cannot mate an ape with itself!");
          return
        }
       }catch(e){

       }

       if(p.father!= null && p.mother!=null){
        let price = await getApeMatePrice(p);
        setApeTokenPrice(price);
       }else{
        setApeTokenPrice(0);
       }

       
        setParent(p);
    }
    setShow(false);
  };
  const handleShow = () => {
    setShowChooseSeasonModal(false)
    setShow(true)
  };
  const handleChooseSeasonShow = (flag) => {
    setParentFlag(flag);
    setShowChooseSeasonModal(true)
  };

  const breedApeNow = ()=>{
    setBreedStart(1);
    props.breedApe(parent,props.user.user,(type,data)=>{
      if(type =="success"){
        setBreedStart(0);
        props.getApeNFTByUser(props.user.user,constants.APE_BREED_ADDR,true);
        setPageView(false);
        setParent({father:null,father_addr:null,father_count:null,mother:null,mother_addr:null,mother_count:null})
        setParentFlag(0);
      }else{
        SetShowError(data);
        setBreedStart(0);
      }
    })
  }

  const backList = ()=>{
    setBreedStart(0);
    setPageView(false);
    setParent({father:null,father_addr:null,father_count:null,mother:null,mother_addr:null,mother_count:null})
    setParentFlag(0);
  }


  return (

    <div className="" style={{minHeight:"600px"}}>
      {pageView ?
      <>
      <div className="container  ">
      <button className="btn btn-previous ms-5 font-18"onClick={()=>{backList()}} disabled={isBreedStart == 1}> &#60; Back</button>
        <div className="row justify-content-center">
          <div className="col text-center">
            <h2 className="heading-3 mb-0 mb-5">Choose apes to mate!</h2>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="">
              <div className="ape_top_banner text-center">
                {parent.father !=null ?
                 <div>
                  <div className="ape_top_banner text-center">
                    <img src={parent.father.image} className="img-fluid" />
                  </div>
                  <h3>{parent.father.name}</h3>
                  <h4>{parent.father_count}/3</h4>
               </div>
                :
                <div>
                  <div className="ape_top_banner text-center">
                    <img src="/assets/images/character.gif" className="img-fluid" />
                  </div>
                  <h3>  &nbsp;</h3>
                  <h4> &nbsp;</h4>
                </div>
              }
               

                {/* <button className="btn btn-theme-regular">Choose Ape</button> */}
                <button className="btn btn-previous me-1 font-18" onClick={()=>{handleChooseSeasonShow(1)}}> {parent.father !=null? "Change": "Select"}</button>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div className=""  >
                <div className="ape_top_banner text-center py-5">
                 <h3> Select apes that you want to mate together.</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div className="ape_top_banner text-center">
                {parent.mother !=null ?
                 <div>
                  <div className="ape_top_banner text-center">
                    <img src={parent.mother.image} className="img-fluid" />
                  </div>
                  <h3>{parent.mother.name}</h3>
                  <h4>{parent.mother_count}/3</h4>
               </div>
                :
                <div >
                  <div className="ape_top_banner text-center">
                    <img src="/assets/images/character.gif" className="img-fluid" />
                  </div>
                  <h3>  &nbsp;</h3>
                  <h4> &nbsp;</h4>
                </div>
              }
               

                {/* <button className="btn btn-theme-regular">Choose Ape</button> */}
                <button className="btn btn-previous me-1 font-18" onClick={()=>{handleChooseSeasonShow(2)}}> {parent.mother !=null? "Change": "Select"}</button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row justify-content-center py-3">
          <div className="col-md-12">
            <h2 className="heading-3 mb-0 text-center">Wait For Text</h2>
          </div>
        </div> */}
          <div className="row justify-content-center py-3">
            <div className="col-md-2">
         {(parent.father!=null && parent.mother!=null) &&
             <>
              <button className="btn btn-previous me-1 font-16" onClick={breedApeNow} disabled={isBreedStart == 1}> {isBreedStart == 0 ? "Start Mating (APEIN "+(apeTokenPrice >0 ? "- "+apeTokenPrice/(10**18) : "")+")" : "Mating" }</button>
              <br/>
              <div className="col-md-12 text-center"><br/><h4>Out of APEIN?</h4>
                <div className="mt-3">
                  <p className="text-font-primary">  <a href="https://app.sushi.com/swap?inputCurrency=eth&outputCurrency=0x8bbf1DcCBEdD5c70d8E793d432fB56b848DD1698" target="_blank" className="text-font-primary text-center" style={{textDecoration:"none"}}><img src="/assets/images/round-cart.png" className="img-fluid" /> Buy Now </a></p>
                
                </div>
                </div>
              </>
        }
            <br/>
            {/* <div className="alert alert-danger">xxxx   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div> */}
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            </div>
          </div>
      </div>

      <ChooseSeason handleShow={seasonSelect} show={showChooseSeasonModal} handleCloseSeasonModal={handleCloseSeasonModal}   />
      {show&&
        <SelectApeModal show={show} handleClose={handleClose} seasonAddr={seasonAddressSelected} />
      }
      </>
      :
      <>
      <div className="row justify-content-md-center">
        <div className="col-md-9 d-flex justify-content-md-between">
          <h2 className="heading-3 mb-0 text-center py-3">Your New Apes</h2>
          <button className="btn btn-previous me-1 font-16" onClick={()=>{setPageView(true)}}>Mate Ape</button>
        </div>
        <div className="col-9 py-3 ">

        {breedApes.totalToken == null &&
          <h4 className="text-center"> Loading...</h4>
        }

        {breedApes.totalToken == 0 &&
          <h4 className="text-center"> No apes have been mated yet.</h4>
        }
        {breedApes.data.map((v,i)=><GeneratedApe ape={v} key={i} />)}
       
        <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
     

        </div>
      </div>
      </>
      }
      {showError!="" && 
        <AlertModal message={showError} show={showError!=""} handleCloseAlert={()=>{SetShowError("");}}></AlertModal>
      } 
    </div>
  );
};


// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  apes: (state) => state.apes,
  apeNfts: (state) => state.apeNfts,
});
const mapDisactcProps = {breedApe,getApeNFTByUser};
export default connect(structuredSelector, mapDisactcProps)(Breeding);

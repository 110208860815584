import React from 'react'

const ApeMeter = (props) =>{
  let image = 'rarity_null.png';
  switch(props.value){
    case 6 : image = 'rarity_pure.png';break;
    case 5 : image = 'rarity_mythic.png';break;
    case 4 : image = 'rarity_legend.png';break;
    case 3 : image = 'rarity_rare.png';break;
    case 2 : image = 'rarity_common.png';break;
    case 1 : image = 'rarity_common.png';break;
  }
  return(
      <img src={'/assets/images/'+image} width="150"/> 
  )
}

export default ApeMeter
import React,{useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import {Link,withRouter} from 'react-router-dom';


import IntroBlock from "../../components/IntroBlock"
import Step1 from "../../components/Play/Steps/step1";
import Step2 from "../../components/Play/Steps/step2";
import Step3 from "../../components/Play/Steps/step3";
import Step4 from "../../components/Play/Steps/step4";
import Step5 from "../../components/Play/Steps/step5";
import ApeMaker from "../../components/Tutorial/ApeMaker";
import Basic from "../../components/Tutorial/Basic";
import Breeding from "../../components/Tutorial/Breeding";


const HowToPlay = (props) => {
  
  const [step,setStep] = useState(0);

  const slides =[
    {title:"Welcome Traveler",subTitle:"Your Guide To Ape Island!",details:"Ahoy primatey and welcome to Ape Island! I’m Captain Poly and I’ll be your guide through the jungle. Ape Island is the most thrilling ape experience on the seven seas. Using the Ape Maker here on the island, you can discover rare and unique apes to be added to your crew. Your crew is truly your own and you are free to trade them or expand your collection for future plunders. Now let's head over to Ape Maker and get you your first mate!",step:"0",stepView:<Step1/>,paddingLeft:"25px"},
    {title:"How To Play?",subTitle:"Credits Explained",details:"This, landlubber, is the Ape Maker! This is where you can discover new recruits for your crew. To do that you’re going to need credits which you can get by holding Ape In NFTs. Don't have any credits? Head over to OpenSea in search of some. Once you have credits, you can begin looking for new recruits.",step:"1",stepView:<Step2/>,paddingLeft:"60px"},
    {title:"Secure Your Crew",subTitle:"Minting Explained",details:"Thar she blows! Once you discover your first recruit, you have the option to mint the ape and add him/her to your crew. Minting can’t be done with your credits; you will have to use ETH or PGU. <br/><br/>Hint: Whether you use ETH or PGU, you will need a little ETH to pay for gas on the Ethereum Network. ",step:"2",stepView:<Step3/>,paddingLeft:"25px"},
    {title:"Secure Your Crew",subTitle:"Alpha Apes Explained",details:"Shiver me timbers! This recruit has never been minted which means you have the chance to go Alpha. Promote this recruit to Alpha and you can secure a \"1 of 1\" crew mate. Go Alpha before some scurvy dog recruits your ape!",step:"3",stepView:<Step4/>,paddingLeft:"25px"},
    {title:"You Did It!",subTitle:"Time For You To Ape In",details:"You did it! Now that you've got your sea legs, I'll leave you to your business. If you ever need help come find me on Ape Island.",step:"4",stepView:<Step5/>,paddingLeft:"60px"},
  ];

  const currentSlide = slides[step];
  const [activeTab, setActiveTab] = useState('1');
  function change_selected(key) {
    setSelectedTab(key);
  }
  function handleTabChange(tab_name) {
    if(tab_name=='close'){
      props.history.push('/app_maker')
    }else{
      setActiveTab(tab_name);
    }
  }
  return (
    <>
      <div className="tutorial_flow">
        <Tabs activeKey={activeTab} onSelect={(k) => handleTabChange(k)} className="justify-content-center">
          <Tab eventKey="1" title="The Basics">
            <Basic handleTabChange={handleTabChange} />
          </Tab>
          <Tab eventKey="2" title="Ape Maker">
            <ApeMaker handleTabChange={handleTabChange} />
          </Tab>
          <Tab eventKey="3" title="Mating 101">
            <Breeding handleTabChange={handleTabChange} />
          </Tab>
        </Tabs>
      </div>
      {/* <div className="row">
        <div className="col-md-6">
          <IntroBlock  paddingLeft={currentSlide.paddingLeft} title={currentSlide.title} subTitle={currentSlide.subTitle} details={currentSlide.details} 
          currentStep={step} maxStep={5} changeStep={setStep}/>
        </div>

        <div className="col-md-6 text-center mt-5">
          {currentSlide.stepView}
        </div>
      </div> */}
    </>
  );
};

export default withRouter(HowToPlay);

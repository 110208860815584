import React,{useState} from "react"

import ApeMeter from "./ApeMeter"

const StatsPanel = (props) =>{
  const [showPanel,setShowPanel] = useState(true);
  const {ape} = props;
  let myRef = React.createRef();

  const togglePanel = ()=>{
    if(showPanel){
      // hide
      myRef.current.style.left='-27.25rem';
    }else{
      // show
      myRef.current.style.left='-12.25rem';
    }

    setShowPanel(!showPanel);
  }

  let getAttrVal = (key)=>{
    let obj = ape.attributes.find((v)=>v.trait_type == key)
    if(obj){
      return obj.value
    }else{
      return "N/A"
    }
  }

  let countStar = (value)=>{
      let obj = ape.attributes.filter((v)=>v.value == value)
      return obj.length;
    }
  
    let starsCount = [countStar('Chadium12'),countStar('Chimp'),countStar('Gold'),countStar('Liberty'),countStar('Nebula'),countStar('Orang'),countStar('Rich'),countStar('Wood')].sort().reverse()[0]   



  return(
    <aside className="visible monkey_stats" ref={myRef}>
        <ul>
          <li>
            <div className="item_details">
              <i className="fas fa-sword" /> Attack <label>{getAttrVal('Attack')}</label>
            </div>
          </li>
          <li>
            <div className="item_details">
              <i className="fas fa-shield" /> Defense <label>{getAttrVal('Defense')}</label>
            </div>
          </li>
          <li>
            <div className="item_details">
              <i className="fas fa-bolt" /> Speed <label>{getAttrVal('Speed')}</label>
            </div>
          </li>
          <li>
            <div className="item_details">
              <i className="fas fa-brain" /> Intellect <label>{getAttrVal('Intellect')}</label>
            </div>
          </li>
          <li>
            <div className="item_details">
              <i className="fas fa-magic" /> Ability <label>{getAttrVal('Ability')}</label>
            </div>
          </li>
          <li>
            <div className="item_details">
              <i className="fas fa-monkey" /> Nature <label>{getAttrVal('Nature')}</label>
            </div>
          </li>
   
          {/* <li>
            <div className="item_details">
              <i className="fas fa-monkey" /> PGKF <label>{getAttrVal('PGFK Balance')}x</label>
            </div>
          </li>
          
          <li>
            <div className="item_details">
              <i className="fas fa-monkey" /> Banana <label>{getAttrVal('Banana Bonus')}x</label>
            </div>
          </li> */}

        </ul>
        
        <span className="sidebar-toggle" aria-current="page" style={{cursor:"pointer"}} onClick={()=>{togglePanel();}}><i className={showPanel ? "fa fa-eye-slash mt-3" : "fa fa-eye mt-3"}></i></span>
        {props.rarity!=false &&
          <ApeMeter value={starsCount} meter_position="left" />
        }
      </aside>
  )
}


export default StatsPanel
import React from "react";
import {APE_TOKEN_SEASON1_ADDR} from "../../../constants"
import {APE_TOKEN_SEASON2_ADDR} from "../../../constants"

const ImageBlock = (props) => {
  return (
    <div className="reward-box ape_box">
      <div
        className="reward-frame test text-center"
        style={{ padding: "1rem 4.5 rem !important" }}
      >
        {(props.currentAddress == APE_TOKEN_SEASON1_ADDR || props.currentAddress == APE_TOKEN_SEASON2_ADDR) &&
          <>
          <span className="info-success">
            <img src={props.alpha ? "/assets/images/alpha_blue.png":"/assets/images/yellow.png"} className="mt-0"/>
          </span>
          {props.alpha &&
            <span className=" ownde_info">
              <img src="/assets/images/1.png" width="55" className="mt-0" />
            </span>
          }
          </>
      }

        <img src={props.image} width="126" className="gimg mt-0"/>
      </div>
    </div>
  );
};

export default ImageBlock;

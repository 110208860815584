import React,{useState,useEffect} from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import ApeBody from "../../components/ApeBody";
import StatsPanel from "../../components/StatsPanel";
import GalleryList from "../../components/GalleryList";

import * as constants from "../../constants";
import {canBeElder,makeElder} from "../../actions";

const ApeGallery = (props) => {
  const {user} = props;
  const [ape,setApe] = useState(false);
  const [twitterShare,setTwitterShare] = useState(true);
  const [showOverlay,setOverlay] = useState(true);
  const [apeAddress,setApeAddress] = useState(constants.APE_TOKEN_SEASON3_ADDR);
  const [showElderButton,setElderButton] = useState(false);
  const [converting,setConverting] = useState(false);

  useEffect(async()=>{
    setElderButton(false);
    if(ape){
      console.log("ape",ape);
      let flag =await canBeElder(ape.id,apeAddress);
      console.log("flagflag",flag);
      setElderButton(flag);
    }
  },[ape]);

  const makeElderApe =()=>{
    setConverting(true);
    makeElder(ape.id,apeAddress,user.user,async(type,data)=>{
      setConverting(false);
      if(type=="error"){
        alert(data);
      }else{
        let flag =await canBeElder(ape.id,apeAddress);
        setElderButton(flag);
      }
    })
  }

  const shareTwitter = (app)=>{
    let name = constants.APE_TOKEN_SEASON1_ADDR == apeAddress ? "s1" : "s2";
    if(constants.APE_BREED_ADDR == apeAddress){
      name='mate';
    }

    window.open("https://twitter.com/share?text=Check out my new friend on Ape Island! Where fun meets NFTs and DeFi.&url="+constants.DOMAIN_NAME+"gallery/"+name+"/"+app.id+"&hashtags=ApeIn,ApeIsland");
    constants.GPageEvent("Social","Twitter-Share","Share");
  }

  return (
    <>  
      <div className="row">
        <div className="col-md-6 pt-3 mt-5 text-center">
   
          <div className="">
            <ApeBody ape={ape} showOverlay={showOverlay} blockType="gallery" currentApeContract={apeAddress}/>
          </div>
          <br/>
          <br/>
          {/* {!ape  ?
            <h2 className="heading-3 mb-0 text-center my-5">
              Choose An Ape To View &nbsp;
              <img src="/assets/images/blue_next.png" width="40" />
            </h2>
          :
           <h2 className="heading-3 mb-0 text-center my-5" onClick={()=>{shareTwitter(ape)}} style={{cursor:"pointer"}} hidden={!twitterShare}>
            Share Your Ape &nbsp;
            <img src="/assets/images/blue_prev.png" width="40" />
          </h2>
          } */}
          <br/>
          {/* {(ape && showElderButton) && 
          <button className="overlay_btn align-center" disabled={converting} onClick={()=>{makeElderApe()}}> {converting ? "Converting...." : "Convert to Elder (5000 APEIN)"}</button>
          } */}

        </div>
        <div className="col-md-6">
          <GalleryList setApe={setApe} setTwitterShare={setTwitterShare} setApeAddress={setApeAddress} currentAddress={apeAddress}/>
          
        </div>
      </div>
    </>
  );
};

// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
});
const mapDisactcProps = { };
export default connect(structuredSelector, mapDisactcProps)(ApeGallery);


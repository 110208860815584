import React, { useState } from 'react'

const DemoRewardBox = () => {
  return (
    <div className="col-md-6 mt-4">
      <div className="reward-box gallery_box">
        <h4 className="text-center">Worthless Banana</h4>
        <div className="reward-box ape_box">
          <div className="reward-frame test text-center">
            <span className="info-success">1</span>
            <img src="https://ape-demo-v1-87432479382524423490342.s3.amazonaws.com/ape_season_two/ape_elder/ape__109_1631742131.png" width={126} className="gimg mt-0" />
          </div>
        </div>
        <div class="empty_space_bg_2 mt-3"><p className="text-font-primary text-center" style={{cursor: 'pointer'}}><img src="/assets/images/round-like.png" className="img-fluid" /> XXX Credits</p></div>
      </div>
    </div>
  )
}

const Basic = (props)=>{
  
  const [isOpened, setIsOpened] = useState(true);

  function toggle() {
    setIsOpened(wasOpened => !wasOpened);
  }
  return (
    <div className="row justify-content-center">
      <div className="col-md-4">
        <div className="t_label_icon"><img src="/assets/new_images/tutorial-icon-01.png" className="img-fluid" /></div>
        <img src="/assets/new_images/tutorial-label-01.png" className="img-fluid mb-4" />

        {isOpened?
        <div>
          <h1 className="logo mb-4" style={{fontSize: '2rem'}}>Welcome Traveler</h1>
          <p className="general">Ahoy primatey and welcome to Ape Island! I’m Captain Ploy and I’ll be your guide through the jungle. Ape Island is the most thrilling ape experience on the seven seas. Using the Ape Maker here on the island, you can discover rare and unique apes to be added to your crew. Your crew is truly your own and you are free to trade them or expand your collection for future plunders. Now let's head over to Ape Maker and get you your first mate!</p>
          
          <div>
            <button className="btn btn-theme-primary font-18" onClick={toggle}>Continue</button>
          </div>
        </div>
        :
        <div>
          <h1 className="logo mb-4" style={{fontSize: '2rem'}}>1) Ape Credits Explained</h1>
          <p className="general">Ahoy primatey and welcome to Ape Island: Ethereum Season 3! I'm Captain Poly and I'll be your guide around Ape Island. Ape Island is the most thrilling ape experience in all the lands. Using the Ape Builder here on the island, you can discover rare and unique apes to be added to your crew. Your crew is truly your own and you are free to trade them or expand your collection for future plunders. Now let's head over to Ape Builder and get you your first mate (or second if you've built with us before)!</p>
          <div>
            <button className="btn btn-previous me-1 font-18" onClick={toggle}>Back to Basic</button>
            <button className="btn btn-theme-primary font-18" onClick={() => props.handleTabChange('2')}>Making Apes</button>
          </div>
        </div>
        }
      </div>
      {isOpened?
      <div className="col-md-4">
        <div className="t_close_icon" onClick={() => props.handleTabChange('close')}><img src="/assets/new_images/tutorial-close-icon.png" className="img-fluid" /></div>
          <img src="/assets/new_images/captain-poly-wave_10-7-2021.gif" className="img-fluid" />
      </div>
      :
      <div className="col-md-4">
        <div className="t_close_icon" onClick={() => props.handleTabChange('close')}><img src="/assets/new_images/tutorial-close-icon.png" className="img-fluid" /></div>
          <img src="/assets/new_images/tutorial-frame-01.png" className="img-fluid" />
      </div>
      }
    </div>
  )
}

export default Basic
import React from "react";

const OnBoarding = () => {
  return (
    <div>
      <div className="row bg-light">
          <div className="col-md-3">
            <div className="ape_gallery_item text-center">
              <h4>Curious Danbert</h4>
              <div className="gallery_thumb_view">
                <img src="/assets/images/pirate-ape.png" className="img-fluid" />
              </div>
              <button className="btn btn-theme-regular">Dr. Ape Liberty</button>
            </div>
          </div>
        </div>

      <div style={{height: '200px'}}></div>
      <div className="mainOnBoarding">
        <div className="wooden_pole"><h2 className="">Ape Onboarding</h2></div>

        <div className="detail_section">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-4">
              <div className="content">Your apes are going at it. ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</div>
            </div>
            <div className="col-md-6">
              <button className="btn-social-gem">Show Me How</button>
            
              <button className="btn btn-connect py-3 font-18">Skip Tutorials</button>
            </div>

            <div className="col-md-2">
              <div className="img_ch"><img src="/assets/new_images/ape_char_new.png" className="img-fluid" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnBoarding;
import React from "react";
import RewardFrame from "./RewardFrame"

import {openseaUrl} from ".././../utlis/index"

const RewardBlock = (props) => {
   let URL ='https://opensea.io/assets/pgfks';
   if(props.voucher.name !='PGFK'){
    URL=`https://opensea.io/assets/${props.contractAddress}/${props.vId}`;
   }

  return (
    <div className="reward-box">
      <h4 className="reward">{props.voucher.name}</h4>
      <RewardFrame {...props}/>
      <p className="text-font-primary text-center">
      {props.voucher.total == null ?
         <> <img src="/assets/images/round-warning" className="img-fluid" />  Cannot Find NFT</>
      :
        <>
        {props.voucher.total > 0 ?
         <><img src="/assets/images/round-like.png" className="img-fluid" /> {props.voucher.credits} Credits </>
        :
        <> <a href={URL} target="_blank" className="text-font-primary text-center" style={{"textDecoration":"none"}}> <img src="/assets/images/round-cart.png" className="img-fluid" /> Buy Now</a></>
        }
        </>
      } 

       
        
      </p>
    </div>
  );
};

export default RewardBlock;

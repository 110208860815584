import React, { useState } from 'react'
import {Link} from 'react-router-dom';
const Breeding = (props)=>{
  const [isOpened, setIsOpened] = useState(true);

  function toggle() {
    setIsOpened(wasOpened => !wasOpened);
  }

  return (
    <div className="row justify-content-center">
      <div className="col-md-4">
        <div className="t_label_icon"><img src="/assets/new_images/tutorial-icon-03.png" className="img-fluid" /></div>
        <img src="/assets/new_images/tutorial-label-03.png" className="img-fluid mb-4" />

        {isOpened? 
          <div>
            <h1 className="logo mb-4" style={{fontSize: '1.75rem'}}>1) Mating Explained</h1>

            <p className="general">The total cost to mate two Apes is dependent on the total number times each Ape has mated prior.</p>
            <p className="general">For example, if Ape1 has mated 1 time prior and Ape 2 has mated 2 times prior, the cost to mate Ape1 + Ape2 would equal 200 APEIN + 300 APEIN = 500 APEIN total. For more details, <a href="https://docs.ape.in/apein-token/details" target="_blank"> click here</a>.</p>

            <div>
              <button className="btn btn-theme-primary btn-block font-18" onClick={toggle}>How Mating Works</button>
            </div>
          </div>
        :
          <div>
            <h1 className="logo mb-4" style={{fontSize: '1.5rem'}}>2) How To Mate Your Apes</h1>

            <p className="general">To get started with mating, visit your gallery and select an ape you'd like to mate. You should see a button on the left-hand side of the page that says "Ape Mating". This will take you to the Ape Mating area where you can begin a new journey with your apes.</p>

            <div>
              <Link to="/app_maker" className="btn btn-theme-primary btn-block font-18">Ape Mating</Link>
            </div>
          </div>
        }
      </div>
      {isOpened? 
      <div className="col-md-4">
        <div className="t_close_icon" onClick={() => props.handleTabChange('close')}><img src="/assets/new_images/tutorial-close-icon.png" className="img-fluid" /></div>
        <img src="/assets/new_images/ape-mating-explained.png" className="img-fluid" />
      </div>
      :
      <div className="col-md-4">
        <div className="t_close_icon" onClick={() => props.handleTabChange('close')}><img src="/assets/new_images/tutorial-close-icon.png" className="img-fluid" /></div>
        <img src="/assets/new_images/tutorial-frame-02.png" className="img-fluid" />
      </div>
    }
    </div>
  )
}

export default Breeding
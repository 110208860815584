import React,{useEffect,useState} from "react";
import ImageBlock from "../ApeDetail/DetailBlock/ImageBlock"
// ApeDetail/DetailBlock/ImageBlock"

import {getBreedCount} from "../../actions"

const BreedApeImageBox = (props) => {
  const {ape,seasonAddr} = props;
  const [mateCount,setMateCount] = useState('');

  useEffect(async()=>{
    let bal = await getBreedCount(ape.id,seasonAddr);
    setMateCount(bal)
  },[])

  return (
    <div className="reward-box gallery_box" onClick={props.setApe}>
      <h4 className="text-center">{ape.name}</h4>
      <ImageBlock  image={ape.image} alpha={ape.is_alpha} />
      <p className="text-font-primary text-center"  style={{cursor:"pointer",marginBottom:"0px"}} onClick={props.setApe}>
        Choose
      </p>
      <p className="text-font-primary text-center"  style={{cursor:"pointer"}} >
        ({mateCount}/3)
      </p>

    </div>
  );
};

export default BreedApeImageBox;

import React,{useEffect,useState} from "react";
import {Link} from 'react-router-dom';

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { buildApe,getPGFKBalance,getTotalSupply} from '../../actions';

import * as constants from "../../constants"

import ApeBody from "../../components/ApeBody"

const AppMaker = (props) => {
  const {user,buildApeObject} = props;
  const [currentApeContract,setCurrentApeContract] = useState(constants.APE_TOKEN_SEASON3_ADDR);
  let final_ape = buildApeObject.data;
  useEffect(()=>{
      if(user.user){
        // props.getTotalSupply();
        // props.getPGFKBalance(user.user.eth_address);
        // constants.GPageView("Ape Builder","/app_maker");
        // constants.GScreenView("Ape Builder Screen");
      }
  },[])

 const build = ()=>{
   if(user.user.tot_credits >= constants.IMG_GENERATE_CREDIT){ 
     if(buildApeObject.type!="request"){
        props.buildApe({eth_address:user.user.eth_address,pgfk_balance:props.PGFKBalance,contract_address:currentApeContract})
        constants.GPageEvent("Generate Ape Clicked","Ape Generate","Generate");
      }

   }else{
    constants.GPageEvent("Buy Credits","Buy More Credit","BuyCedit"); 
    props.history.push('/buy_credits');
   }
 }


  return (
       <>
      <div className="container mt-4">

        <div className="row justify-content-center">
          <div className="col-md-6">
            <h2 className="text-center text-white">Apes are migrating to Avalanche. Check out the migration by clicking the button below.</h2>
            <div className="text-center">
               <br/>
              <a href="https://avax.ape.in/login" target="_blank" class="btn btn-theme-primary font-18">Ape Island Avalanche Expansion</a>
            </div>
            {/* <ApeBody buildApeObject={buildApeObject} build={build} ape={final_ape} showOverlay={true} currentApeContract={currentApeContract}/> */}
          </div>
        </div>
      </div>

    </>
  );
};

// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  buildApeObject: (state) => state.buildApe,
  PGFKBalance: (state) => state.PGFKBalance,
  apes: (state) => state.apes,
  APESupply: (state) => state.APESupply,
});
const mapDisactcProps = {buildApe,getPGFKBalance,getTotalSupply};
export default connect(structuredSelector, mapDisactcProps)(AppMaker);

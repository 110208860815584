import React,{useEffect,useState} from "react";
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';
import {Link,withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {setSound,checkLogin,web3} from "../../actions"

let audio = new Audio('/assets/ape-island-season-2_3-25-2021.mp4');
audio.loop = true;

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      steps: [
        {
          content: 'In order to generate apes, you will need credits.',
          placement: 'bottom',
          /*disableBeacon: true,*/
          target: '.nav-credit-area',
        },
        {
          content: 'You Can View Your Generated Apes In The Gallery',
          placement: 'bottom',
          target: '.nav-gallery-area',
        },
        {
          title: 'Generate',
          content: 'If you have the required credits this button will generate new apes.',
          placement: 'top',
          target: '.generate_ape_btn',
          placementBeacon: 'bottom',
        },
        {
          title: 'Monkey Stats',
          content: 'Each Ape comes with a unique pair of stats that will help them across all of Ape Isands.',
          placement: 'right',
          target: '.monkey_stats',
        },
      ],
    };
  }

 changeSound = function(){
   const {appSound} = this.props;
    if(appSound){
      this.props.setSound(false);
      audio.pause();
    }else{
      this.props.setSound(true);
      audio.play(); 
    }
  }

  handleClickStart = (e) => {
    e.prevetDefault();
    this.props.state = true;
  }

  handleJoyrideCallback = (data) => {
    const {location} = this.props;
    const { status, type, index } = data;
    const finishedStatuses= [STATUS.FINISHED, STATUS.SKIPPED];
  
    if (location.pathname === '/app_maker' && index === 0) {
      setTimeout(() => {
        this.setState({ run: true });
      }, 400);
    }

  };

  
  componentWillMount(){
    // this.props.checkLogin();
  }

  componentDidMount() {
    this.checkLoginPrevent();
  }

  checkLoginPrevent(){
    const { user,login, appSound } = this.props;
    if(login && !user.login){
      this.props.history.push('/login');
    }else{
      if(appSound){
        audio.onloadeddata = function() {
          audio.play();
        };
        audio.play();
      }
    }
  }

  componentWillUnmount(){
    audio.pause();
  }
  setActiveClass(url,cssName){
    const {location} = this.props;
    this.checkLoginPrevent();
    if(location.pathname == url){
      return `${cssName} nav-active`
    }else{
      return cssName
    }
  }

  getAddress(){
    if(window.ethereum.selectedAddress){
      let addr = web3.utils.toChecksumAddress(window.ethereum.selectedAddress)
      return addr.substr(0,5)+"..."+addr.substr(addr.length-4,4)
    }else{
      return null
    }
  }

  render() {
    const { user,appSound } = this.props;
    
    return (
      <>
        <Joyride
          floaterProps={{ hideArrow: true }}
          hideCloseButton={true}
          callback={this.handleJoyrideCallback}
          continuous={true}
          getHelpers={this.getHelpers}
          run={this.state.run}
          scrollToFirstStep={false}
          showProgress={true}
          showSkipButton={false}
          steps={this.state.steps}
          showProgress={false}
          locale={{
            next: '', last: '', back: '', skip: 'Skip Tour'
          }}
          buttonNext={{
            backgroundColor: '#ff0'
          }}
          styles={{
            options: {
              zIndex: 10000,
              width: 300,
              overlayColor: 'rgba(10, 10, 10, 0.5)',
              backgroundColor: 'transparent',
              arrowColor: '#FFE3A1',
              textColor: '#6F5025',
              beaconSize: 20,
            },
          }}
        />
        <nav className="navbar navbar-expand-lg d-block">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav w-100 justify-content-evenly">
              <li className="nav-item ms-3-5"><span className="nav-link nav-gem-btn" aria-current="page" style={{cursor:"pointer"}}   onClick={()=>{this.changeSound()}}><i className={appSound? "fa fa-volume mt-3" : "fa fa-volume-mute mt-3"}></i></span></li>
              <li className="nav-item">
                <span  className={user.user.tot_credits >0 ? "nav-link nav-credit-area nav-credit  pt-3" :"nav-link nav-credit-area nav-no-credit  pt-3"}> {user.user ? user.user.tot_credits : 0 } Credits</span>
              </li>
              <li className="nav-item mt-1">
                <Link to={`/app_maker`} className={this.setActiveClass('/app_maker','nav-link nav-no-button-static pt-2-5')}>Ape Builder</Link>
              </li>
              <li className="nav-item mt-1">
                <Link to={`/gallery`} className={this.setActiveClass('/gallery','nav-link nav-gallery-area nav-no-button-static pt-2-5-link nav-no-button-static pt-2-5')}>Ape Gallery</Link>
              </li>
              {/* <li className="nav-item mt-1">
                <Link to={`/mating`} className={this.setActiveClass('/mating','nav-link nav-gallery-area nav-no-button-static pt-2-5-link nav-no-button-static pt-2-5')}>Ape Mate</Link>
              </li> */}
              <li className="nav-item mt-1">
                  <Link to={`/how_to_play`} className={this.setActiveClass('/how_to_play','nav-link nav-no-button-static pt-2-5')} >How to Play</Link>
              </li>
             
              {/* <li className="nav-item mt-1">
                  <a href="https://docs.ape.in/apein-token/details" className={this.setActiveClass('/how_to_play','nav-link nav-no-button-static pt-2-5')}  target="_blank">Mating 101</a>
              </li> */}
              <li className="nav-item mt-1 me-5">
              {user.login? 
                  <span className="nav-link nav-checked pt-2"><img src="/assets/images/nav-metamask.png" /> {this.getAddress()}</span>
                :
                <Link to={`/login`} className={this.setActiveClass('/login','nav-link nav-no-button-static pt-2-5')}>No Connection</Link>
                  }
              
              </li>
            </ul>
          </div>

        </nav>
      </>
    )
  }

}

const structuredSelector = createStructuredSelector({
  user: state => state.user,
  appSound: state => state.appSound,
})
const mapDisactcProps = { setSound,checkLogin };

export default connect(structuredSelector,mapDisactcProps)(withRouter(NavBar))
import {ETH_ENV} from '../constants'

const chainData = {
  "production": [
    {
      "name": "Ethereum Mainnet",
      "short_name": "Ethereum",
      "key": "eth_prod",
      "chainId": 1,
      "shortName": "eth",
      "chain": "ETH",
      "network": "mainnet",
      "networkId": 1,
      "nativeCurrency": {
        "name": "Ether",
        "symbol": "ETH",
        "decimals": 18
      },
      "rpc": [
        "https://mainnet.infura.io/v3/${INFURA_API_KEY}",
        "wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}",
        "https://api.mycryptoapi.com/eth",
        "https://cloudflare-eth.com"
      ],
      "faucets": [],
      "explorers": [
        {
          "name": "etherscan",
          "url": "https://etherscan.io",
          "standard": "EIP3091"
        }
      ],
      "infoURL": "https://ethereum.org"
    },
  ],
  "development": [
    {
      "name": "Ethereum Testnet Rinkeby",
      "short_name": "Ethereum",
      "key": "eth_dev",
      "chainId": 4,
      "shortName": "rin",
      "chain": "ETH",
      "network": "rinkeby",
      "networkId": 4,
      "nativeCurrency": {
        "name": "Rinkeby Ether",
        "symbol": "RIN",
        "decimals": 18
      },
      "rpc": [
        "https://rinkeby.infura.io/v3/${INFURA_API_KEY}",
        "wss://rinkeby.infura.io/ws/v3/${INFURA_API_KEY}"
      ],
      "faucets": [
        "https://faucet.rinkeby.io"
      ],
      "explorers": [
        {
          "url": "https://rinkeby.etherscan.io"
        }
      ],
      "infoURL": "https://www.rinkeby.io"
    },
  ]
}
let currentChain;
if(ETH_ENV == 'production'){
  currentChain = chainData['production'];
}else{
  currentChain =  chainData['development'];
}

export const isChainAllow = (chainId)=>{
  let chain = currentChain.find((v)=>v.chainId == chainId);
   if(chain){
     return true;
   }else{
     return false;
   }
}

export const getNetwork = (chainId)=>{
  let chain = currentChain.find((v)=>v.chainId == chainId);
   if(chain){
     return chain;
   }else{
     return false;
   }
}

export default currentChain;
import React, { useState } from 'react'

const ApeMaker = (props)=>{

  const [isOpened, setIsOpened] = useState(1);

  function toggle(val) {
    setIsOpened(val);
  }

  return (
    <div className="row justify-content-center">
      <div className="col-md-4">
      <div className="t_label_icon"><img src="/assets/new_images/tutorial-icon-02.png" className="img-fluid" /></div>
        <img src="/assets/new_images/tutorial-label-02.png" className="img-fluid mb-4" />
        {isOpened == 1 ?
          <div id="view_1">
            <h1 className="logo mb-4" style={{fontSize: '2rem'}}>1) "Normal" Apes</h1>

            <p className="general">Thar she blows! You just discovered your first recruit. Now you have the option to mint the ape and add it to your crew. Minting can’t be done with your credits; you will have to use APEIN and a little bit of ETH for gas. For those who were with us for Season 1 and Season 2, you may remember an "Alpha" version of apes. Season 3 and beyond will not have an "Alpha" option.</p>

            <div>
              <button className="btn btn-theme-primary btn-block font-18" onClick={() => toggle(2)}>Continue</button>
            </div>
          </div>
        : isOpened == 2 ?
          <div id="view_2">
            <h1 className="logo mb-4" style={{fontSize: '2rem'}}>2) "Alpha" Apes</h1>
            <p className="general">Shiver me timbers! This recruit is completely unique; this means you have the chance to Alpha this ape. By promoting this recruit to alpha, you can secure him as a verified 1:1 crewmate, ensuring that ape won’t sail with anyone else. Note: "Alpha" was only available for Season 1 and Season 2.</p>
            <div>
              <button className="btn btn-previous me-1 font-18" onClick={() => toggle(1)}>Previous</button>
              <button className="btn btn-theme-primary font-18" onClick={() => toggle(3)}>Making Apes</button>
            </div>
          </div>
        :
          <div id="view_2">
            <h1 className="logo mb-4" style={{fontSize: '2rem'}}>3) Life Cycles</h1>
            <p className="general">Apes minted as a part of a season launch, for example, Ape Island Season 3, are minted as Adult Apes. Baby Apes can then be minted by mating two adult apes from any season together. Once you've mated an adult ape 3 times it will become eligible to be an Elder Ape and will not be available for mating again. Leveling up to an Elder Ape costs 5,000 APEIN and is only available during certain time periods so be sure to follow us online for details!</p>
            <div>
              <button className="btn btn-previous me-1 font-18" onClick={() => toggle(2)}>Previous</button>
              <button className="btn btn-theme-primary font-18" onClick={() => props.handleTabChange('3')}>Ape Mating</button>
            </div>
          </div>
        }
        

        
      </div>
      {isOpened == 1 || isOpened == 2 ? 
      <div className="col-md-4">
        <div className="t_close_icon" onClick={() => props.handleTabChange('close')}><img src="/assets/new_images/tutorial-close-icon.png" className="img-fluid" /></div>
        <img src="/assets/new_images/ape-how-to-play-creating-your-crew_10-7-2021.png" className="img-fluid" />
      </div>
      :
      <div className="col-md-4">
        <div className="t_close_icon" onClick={() => props.handleTabChange('close')}><img src="/assets/new_images/tutorial-close-icon.png" className="img-fluid" /></div>
        <img src="/assets/images/ape-life-cycles.gif" className="img-fluid"  width="400" />
      </div>
    }
    </div>
  )
}

export default ApeMaker
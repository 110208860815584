export function userReducer(state = { login: false, user: false,network:true }, action) {
  switch (action.type) {
    case "LOGIN_RESET":
      return { login: false, user: false,network:true }
    case "LOGIN_SUCCESS":
      let obj = { login: true, user: action.json.data,network:true }
      return obj
      break;
    case "BUILD_USER_APES_SUCCESS": 
      if(state.login){ 
        let data = action.json.data.user;
        return { login: true, user: data,network:true }
      }
      break;
    case "VOUCHER_CREDITS_SUCCESS":
      if(state.login){ 
        return { login: true, user: action.json.data,network:true }
      }
    break;
    case "LOGIN_ERROR_CALL_NETWORK":  
       return { login: false, user: false,network:false }
      break;
    case "LOGOUT_SUCCESS":  
       return { login: false, user: false,network:true} 
  }
  return state;
}


export function voucherCreditsReducer(state={},action){
  switch(action.type){
    case "VOUCHERS_CREDITS":
      return action.json;
  }
  return state;
}

export function apePriceReducer(state={pgu:0,pguAlpha:0,eth:0,ethAlpha:0},action){
  switch(action.type){
    case "APE_PRICE_SUCCESS":
      return action.json;
  }
  return state;
}
const headers ={'API-KEY':'5070601242847844','API-TOKEN':'jqEyHEZGqXSLvrzQgrKUZeWzG567UEQ5'}
export function fetchReq(url,b_type) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json',...headers}
};
  return dispatch => {
    dispatch({ type: b_type+"_REQUEST" });
    return fetch(url,requestOptions)
      .then(response => response.json())
      .then(json => dispatch({
                      type: b_type+'_SUCCESS',
                      json
                    }))
      .catch(error => dispatch({
                      type: b_type+'_ERROR',
                      error
                    }));
  };
};


export function postReq(url,data, b_type) { 
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',...headers},
        body: JSON.stringify(data)
    };
   return dispatch => {
    dispatch({ type: b_type+"_REQUEST" });
    return fetch(url,requestOptions)
      .then(response => response.json())
      .then(json => dispatch({
                      type: b_type+(json.type=='success'? '_SUCCESS' : '_ERROR'),
                      json
                    }))
      .catch(error => dispatch({
                      type: b_type+'_ERROR',
                      error
                    }));
  };
}


export function putReq(url,data, b_type) { 
  const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' ,...headers},
        body: JSON.stringify(data)
    };

   return dispatch => {
    dispatch({ type: b_type+"_REQUEST" });
    return fetch(url,requestOptions)
      .then(response => response.json())
      .then(json => dispatch({
                      type: b_type+'_SUCCESS',
                      json
                    }))
      .catch(error => dispatch({
                      type: b_type+'_ERROR',
                      error
                    }));
  };
}

export function deleteReq(url,data, b_type) { 
  const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' ,...headers},
        body: JSON.stringify(data)
    };

   return dispatch => {
    dispatch({ type: b_type+"_REQUEST" });
    return fetch(url,requestOptions)
      .then(response => response.json())
      .then(json => dispatch({
                      type: b_type+'_SUCCESS',
                      json
                    }))
      .catch(error => dispatch({
                      type: b_type+'_ERROR',
                      error
                    }));
  };
}
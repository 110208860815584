import React from "react"


const DangerAlert = (props) =>{
  return(
    <footer className="py-5">
      <div className="container">
        <div className={props.full ? "col-md-12 float-end" : "col-md-4 float-end"}>
          <div className="card metamask-alert" >
            <div className="card-header" style={{background:'url("/assets/images/dangerAlert.png")',backgroundSize:'100% 100%',backgroundRepeat:"no-repeat"}}>
                <h2 className="pt-1 font-20 px-3 mt-1 ml-4" title={props.title}>{props.title.slice(0,30)}
                {props.title.length > 30 ? '...' : null}

                </h2>
              </div>
            <div className="card-body pt-3">
            <p className="ps-4 pe-5">{props.description}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}


export default DangerAlert
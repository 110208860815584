import React from "react";


import {Link,withRouter} from 'react-router-dom';
const Home = () => {
  return (
      <div className="container animate__animated animate__bounce ">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="ape_on_boarding">
              <div className="ape_top_banner text-center">
                <img
                  src="assets/images/logo_season_3.png"
                  className="img-fluid"
                  width="430"
                />
                <div className="clearfix"></div>
                <div>
                  <img
                    src="assets/images/ape-season-3-character.png"
                    className="img-fluid"
                    width="200"
                  />
                </div>
                <h1 className="logo" style={{fontSize: '2rem'}}>Welcome To Ape Island</h1>
           
                <Link to={'/login'} className="btn btn-connect py-4 font-18" style={{height: 'inherit'}}>Enter Ape Island</Link>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="col-md-5">
            <h2 className="version mt-0 mb-5 px-3 text-center" style={{backgroundColor: 'rgba(131, 131, 131, 0.25)'}}>
              <img src="/assets/images/info-icon.png" /> Ape Island / Bahamas Update / V.3.0.0
            </h2>
          </div>
        </div>
      </div>
  );
};

export default Home;

import React,{useState,useEffect} from "react";
import {Link} from 'react-router-dom';

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {mintApeWithToken,mintApeWithEth,getApePrice,checkApe} from "../../actions"

import * as constants from "../../constants"

import DetailBlock from "./DetailBlock";

const ApeDetail = (props) => {
  const {buildApeObject,user, currentApeContract} = props;
  const ape = buildApeObject.data;
  const [payBtn,setPayButton] = useState(true);
  const [payProcess,setPayProcess] = useState(false);
  const [payError,setPayError] = useState(false);
  const [mintClicked,setMintClicked] = useState(false);
  
  useEffect(()=>{
    props.getApePrice();
  },[]);

  let showPrice = (p)=>{
    return (p/(1*10**18)).toFixed(2);
  }

  let mintToken = (isAlpha)=>{
    // return props.sellSuccess(ape,isAlpha,{tx:"XXXX"});
    if(!mintClicked){
      setMintClicked(true);
      setPayProcess(true);

      let d = {}
      d['contract_address'] = currentApeContract;
      d['eth_address'] = user.user.eth_address;
      d['is_alpha'] = isAlpha;
      console.log("d",d);

      props.checkApe(ape.id,d,(data)=>{
        // if(payBtn){
          props.mintApeWithToken(ape,currentApeContract,constants.APE_TOKEN_ERC20_ADDR,user.user,isAlpha,callBackMint);
          constants.GPageEvent("Sell Btn Click","Mint With APEIN", isAlpha ? "Alpha Sell" : "Normal Sell");
        // }else{
        //   props.mintApeWithEth(ape,currentApeContract,user.user,isAlpha,callBackMint);
        //   constants.GPageEvent("Sell Btn Click","Mint With Eth",isAlpha ? "Alpha Sell" : "Normal Sell");
        // }

      })

  
    }
  }


  let callBackMint = (type,isAlpha,data)=>{
    if(type =='success'){
      setPayProcess(false);
      props.sellSuccess(ape,isAlpha,data);
    }else{
     setPayProcess('error'); 
     setPayError(data);
     setMintClicked(false);
     constants.GPageEvent("Payment Error","Mint Error", payBtn ? "APEIN Error" :"Eth Error");
    }
  }

  let tryAgain =()=>{
    if(payError == "Uh oh! Looks like someone minted this ape to their crew as an Alpha before you could! Go back to the Ape Builder and try again."){
      window.location.reload();
    }else{
      setPayProcess(false);
      setMintClicked(false);
      setPayError(false);
    }

  }

  return (
    <div
      className="ape_on_boarding ape_buy_screen pt-0"
      style={{
        backgroundImage: "url('/assets/images/bg-without-rope-2.png')",
        backgroundPosition: "center",
        backgroundSize: '100% 100%',
      }}
    >
      <div className="ape_top_banner buy-ape-area" style={{ position: "relative" }}>
        <div className="d-flex justify-content-start">
          <div className="flex-shrink-0">
          <img src="/assets/images/character-avatar.png" className="img-fluid character-avatar" width="120" />
            <h6 className="avatar-text ms-3">Capt Poly</h6>
          </div>

          <div className="mt-5-5 detailBlock_head_section" style={{paddingLeft:"5.5rem"}}>
            <h2 className="heading-3 mb-0 mt-1 text-center">Meet Your Mate</h2>
            <h4>{ape.name}</h4>
          </div>
          <div className="flex-shrink-0">
            {/* <Link to="/app_maker">
              <img
                src="/assets/images/back_button.png"
                className="img-fluid mt-3 ml-5"
                width="150"
              />
            </Link> */}
          </div>
        </div>

        <hr />

        <div className="row justify-content-center mt-1">
          <div className="col-md-8">
            <p className="p-2 text-center">
              {/* <span dangerouslySetInnerHTML={{ __html: props.details }} /> */}
              <strong className="text-center">
                In just a few moments you can have {ape.name}, join
                your crew.
              </strong>
              <span className="d-block">
           
              </span>
            </p>

            <p style={{marginTop:"-5px"}}>
            You have a pretty great looking ape there! If you'd like this ape to join your crew, mint using the button below. If not, clicking "Generate Again" will take you back to the Ape Builder page.
            </p>
            <div className="btns text-center">
            {payProcess == false && 
              <>
              <div className="align-items-center pt-5">
                <h5 style={{fontWeight: 'bold', textAlign: 'center'}}> {props.apePrice.apeInTokenPrice/(10**18)} APEIN</h5>
                <div class="mt-3 ape-smain d-flex justify-content-center align-items-centers" style={{height: '250px'}}>
                  
                  <Link to="/app_maker" class="btn btn-previous me-1 font-18" style={{fontSize: '20px!important', lineHeight: '70px'}}>Generate Again</Link>
                  <button class="btn btn-theme-primary px-3 font-18" onClick={()=>{mintToken(false)}}>Buy This Ape</button>

                
                </div>
              </div>
              </>
            }

              {payProcess == true && 
                  <h3 className="w-100 text-center heading-2 py-3 font-18">Minting...</h3>
                }

                {payProcess == "error" && 
                <div className="d-flex flex-column"> 
                <strong title={payError}  className="w-75 text-center gallery_title py-3 font-16 m-auto" style={{fontColor:"#4B370D"}} style={{maxWidth:'400px',maxHeight:'150px',overflow:'auto'}}><img src="/assets/images/sad_smaily.svg" width="30" /> {payError} </strong> 
                  
                  <strong class="mt-2 heading-2 font-16 err_block" onClick={tryAgain} > <img src="/assets/images/round-cart.png" width="30" /> Try Again </strong>
                
                </ div>
                }

            {/* <div className="btns text-center">
              <div className="mt-3 ape-main d-flex align-items-center" >
                {payProcess == false && 
                  <div className="" style={{margin:"auto"}}>
                    {buildApeObject.allow_alpha &&
                      <div className=" d-flex justify-content-between buy_button" onClick={()=>{mintToken(true)}} style={{cursor:"pointer"}}> 
                        <div>Buy Alpha</div>
                        <div className="count_box">{showPrice(payBtn ?props.apePrice.pguAlpha : props.apePrice.ethAlpha)} {payBtn ?"APEIN" : "ETH"}</div>  
                      </div>
                    }

                    {buildApeObject.allow_normal &&
                      <div className=" d-flex justify-content-between normal_button" onClick={()=>{mintToken(false)}} style={{cursor:"pointer"}}>
                        <div className="w-50 text-center font-18" style={{marginTop:"-4px"}}>Buy</div>
                        <div className="count_box" >{showPrice(payBtn ?props.apePrice.pgu : props.apePrice.eth)} {payBtn ?"APEIN" : "ETH"}</div>
                      </div>
                    }
                  </div>

                  
                }

              {payProcess == true && 
                <h3 className="w-100 text-center heading-2 py-3 font-18">Minting...</h3>
              }

              {payProcess == "error" && 
               <div className="d-flex flex-column"> 
               <strong title={payError}  className="w-75 text-center gallery_title py-3 font-16 m-auto" style={{fontColor:"#4B370D"}} style={{maxWidth:'400px',maxHeight:'150px',overflow:'auto'}}><img src="/assets/images/sad_smaily.svg" width="30" /> {payError} </strong> 
                
                <strong class="mt-2 heading-2 font-16 err_block" onClick={tryAgain} > <img src="/assets/images/round-cart.png" width="30" /> Try Again </strong>
               
               </ div>
              }
              
             
              </div>
            */}
            </div> 

          </div>
        </div>

      
      </div>
    </div>
  );
};


const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  buildApeObject: (state) => state.buildApe,
  apePrice: (state) => state.apePrice,
})
const mapDisactcProps = { mintApeWithToken,mintApeWithEth,getApePrice,checkApe};

export default connect(structuredSelector,mapDisactcProps)(ApeDetail)
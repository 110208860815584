export function apeGalleryReducer(state = {type:'',data:[]}, action){
  // return [{"id":123,"user_id":5,"picture_array":["Head/metaltoy.png","LeftArm/whitefur.png","LeftLeg/space.png","RightArm/space.png","RightLeg/space.png","Torso/metaltoy.png"],"picture":"https://ape-demo-v1-87432479382524423490342.s3.amazonaws.com/apes_images_token/photo_1615375505.png","name":"Remarkable Banana","state_agility":93,"state_attack":52,"state_play":20,"state_speed":40,"state_stamina":85,"state_strength":20,"pgfk_balance":7,"banana_bonus":"2.8","created_at":"2021-03-10T11:25:00.834Z","updated_at":"2021-03-10T11:25:00.834Z","superme":false}]
  switch (action.type) {
    case "APE_TOKEN_DATA_REQUEST":
      if(state.data.length > 0){
       return {...state,type:'request'};
      }else{
       return {...state,type:'new_request'};
      }
    case "APE_TOKEN_DATA_SUCCESS":
      return {type:'success',data:action.json.tokens};
  }
  return state;
}

export function allApeGalleryReducer(state = {type:'',data:[],dataPulled:null,totalToken:null}, action){
  switch (action.type) {
    case "APE_ALL_TOKEN_DATA_REQUEST":
      return {...state,type:'request'};
    case "APE_ALL_TOKEN_DATA_NEW_REQUEST":
      return {type:'new_request',data:[],dataPulled:null,totalToken:null};
    case "APE_ALL_TOKEN_DATA_SUCCESS":
      return {type:'success',data:action.json.tokens,dataPulled:action.json.dataPulled,totalToken:action.json.totalToken};
    case "APE_ALL_TOKEN_EXTRA_DATA_SUCCESS":
      return {type:'success',data:[...state.data,...action.json.tokens],dataPulled:action.json.dataPulled,totalToken:action.json.totalToken};
  }
  return state;
}


export function allBreedApeGalleryReducer(state = {type:'',data:[],dataPulled:null,totalToken:null}, action){
  switch (action.type) {
    case "BREED_APE_ALL_TOKEN_DATA_REQUEST":
      return {...state,type:'request'};
    case "BREED_APE_ALL_TOKEN_DATA_NEW_REQUEST":
      return {type:'new_request',data:[],dataPulled:null,totalToken:null};
    case "BREED_APE_TOKEN_DATA_SUCCESS":
      let object = {...state};
      object.totalToken = action.json.totalToken
      let flag = false;
      for(let i =0 ; i < object.data.length; i++){
        if(action.json.tokenId == object.data[i].id){
          object.data[i] = action.json.token;
          flag =true;
        }
      }
      if(!flag){
        object.data.push(action.json.token);
      }
      return object;
      
    case "BREED_APE_ALL_TOKEN_DATA_SUCCESS":
      return {type:'success',data:action.json.tokens,dataPulled:action.json.dataPulled,totalToken:action.json.totalToken};
    case "BREED_APE_ALL_TOKEN_EXTRA_DATA_SUCCESS":
      return {type:'success',data:[...state.data,...action.json.tokens],dataPulled:action.json.dataPulled,totalToken:action.json.totalToken};
  }
  return state;
}


export function apeS1GalleryReducer(state = {type:'',data:[]}, action){
  // return [{"id":123,"user_id":5,"picture_array":["Head/metaltoy.png","LeftArm/whitefur.png","LeftLeg/space.png","RightArm/space.png","RightLeg/space.png","Torso/metaltoy.png"],"picture":"https://ape-demo-v1-87432479382524423490342.s3.amazonaws.com/apes_images_token/photo_1615375505.png","name":"Remarkable Banana","state_agility":93,"state_attack":52,"state_play":20,"state_speed":40,"state_stamina":85,"state_strength":20,"pgfk_balance":7,"banana_bonus":"2.8","created_at":"2021-03-10T11:25:00.834Z","updated_at":"2021-03-10T11:25:00.834Z","superme":false}]
  switch (action.type) {
    case "APE_SE1_TOKEN_DATA_REQUEST":
      if(state.data.length > 0){
       return {...state,type:'request'};
      }else{
       return {...state,type:'new_request'};
      }
    case "APE_SE1_TOKEN_DATA_SUCCESS":
      return {type:'success',data:action.json.tokens};
  }
  return state;
}

export function allS1ApeGalleryReducer(state = {type:'',data:[],dataPulled:null,totalToken:null}, action){
  switch (action.type) {
    case "APE_ALL_SE1_TOKEN_DATA_REQUEST":
      return {...state,type:'request'};
    case "APE_ALL_SE1_TOKEN_DATA_NEW_REQUEST":
      return {type:'new_request',data:[],dataPulled:null,totalToken:null};
    case "APE_ALL_SE1_TOKEN_DATA_SUCCESS":
      return {type:'success',data:action.json.tokens,dataPulled:action.json.dataPulled,totalToken:action.json.totalToken};
    case "APE_ALL_SE1_TOKEN_EXTRA_DATA_SUCCESS":
      return {type:'success',data:[...state.data,...action.json.tokens],dataPulled:action.json.dataPulled,totalToken:action.json.totalToken};
  }
  return state;
}
  
export function buildApeReducer(state = {type:"",flag:false,data:false,allow_alpha:false,allow_normal:false,error:false}, action){
  switch (action.type) {
    case "BUILD_USER_APES_REQUEST": 
     return  {type:"request",flag:true,data:false,allow_alpha:false,allow_normal:false,error:false}
    case "BUILD_USER_APES_SUCCESS": 
     return {type:"success",flag:true,data:action.json.data.ape,allow_alpha:action.json.data.allow_alpha,allow_normal:action.json.data.allow_normal,error:false}
     case "GET_APES_SUCCESS":
       if(state.data!=false){
         if(state.data.id == action.json.data.ape.id){
          return {...state,data:action.json.data.ape} 
         }else{
          return {...state}
         }
       }else{
         return {...state}
       }
    case "BUILD_USER_APES_ERROR": 
     return {type:"",flag:true,data:false,allow_alpha:false,allow_normal:false,error:action.json.message}
    case "BUILD_USER_APES_RESET": 
     return  {type:"",flag:false,data:false,allow_alpha:false,allow_normal:false,error:false}
  }
  return state;
}

export function getPGFKbalanceReducer(state = 0, action){
  switch (action.type) {
    case "PGFK_BALANCE": 
     return action.json.data
  }
  return state;
}

export function getAPESupplyReducer(state = {totalSupply:null,remaing:null}, action){
  switch (action.type) {
    case "APE_SUPPLY": 
     return action.json
  }
  return state;
}


export function getAppSound(state = true, action){
  switch (action.type) {
    case "SET_APP_SOUND": 
     return action.json
  }
  return state;
}

export function apeNFTReducer(state = {}, action){
  let obj ={}
  switch (action.type) {
    case "APE_NFT_DATA_REQUEST":
      obj = {...state}
      if(typeof obj[action.json.contract_address] !='undefined' && typeof obj[action.json.contract_address].type !='undefined' && obj[action.json.contract_address].type!=""){
        obj[action.json.contract_address].type='request';
      }else{
       
        obj[action.json.contract_address] = {};
       obj[action.json.contract_address]={type:'new_request',data:[],totalToken:null}
      }
      return obj;
    case "APE_NFT_NEW_DATA_REQUEST":
      obj = {...state}
      obj[action.json.contract_address]={type:'new_request',data:[],totalToken:null}
      return obj;
    case "APE_NFT_DATA_SUCCESS":
      obj = {...state}
      obj[action.json.contract_address]={type:'success',data:action.json.tokens,totalToken:action.json.totalToken}
      return obj;
  }
  return state;
}



export function allNFTGalleryReducer(state = {}, action){
  let obj ={}
  switch (action.type) {
    case "APE_NFT_TOKEN_DATA_REQUEST":
      obj = {...state}
      obj[action.json.contract_address] = obj[action.json.contract_address] || {}
      obj[action.json.contract_address].type='request';
      return obj;
    case "APE_NFT_TOKEN_DATA_NEW_REQUEST":
      obj = {...state}
      obj[action.json.contract_address] = obj[action.json.contract_address] || {}
      obj[action.json.contract_address].type='new_request';
      return obj;
    case "APE_NFT_TOKEN_DATA_SUCCESS":
      obj = {...state}
      obj[action.json.contract_address] = obj[action.json.contract_address] || {}
      obj[action.json.contract_address].type='success';
      obj[action.json.contract_address].data= action.json.tokens;
      obj[action.json.contract_address].dataPulled= action.json.dataPulled;
      obj[action.json.contract_address].totalToken= action.json.totalToken;
      return obj;
    case "APE_NFT_TOKEN_EXTRA_DATA_SUCCESS":
      obj = {...state}
      obj[action.json.contract_address].type='success';
      obj[action.json.contract_address].data=[...obj[action.json.contract_address].data,...action.json.tokens];
      obj[action.json.contract_address].dataPulled= action.json.dataPulled;
      obj[action.json.contract_address].totalToken= action.json.totalToken;
      return obj;
  }
  return state;
}

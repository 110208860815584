import React,{useState,useEffect} from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import * as constants from "../../constants"

import {chkTokenId,checkLogin} from "../../actions"

const ApeCheck = (props) => {

  customElements
  const [startId,setStartId] = useState(370);
  const [endId,setEndId] = useState(380);
  const [apiCall,setApiCall] = useState(false);
  const [allApiCall,setAllApiCall] = useState(true);
  const [finaTokens,setFinaTokens] = useState({});
  let tokens = {};
  useEffect(()=>{
    console.log("props",props);
    if(props.location.pathname !="/run_ape_chk/"+constants.UNIQUE_KEY_SEC){
      window.location.href="/login"
    }
    props.checkLogin();
  },[])

  let loadTokens = ()=>{
    if(!apiCall){
      setApiCall(true);
      setFinaTokens({});
      // for(let i =parseInt(startId); i<=parseInt(endId);i++){
        // console.log("i",i);
        // finaTokens[i]= {minted:null,res:null}
        // props.chkTokenId(i,allApiCall,(data)=>{
        //     finaTokens[data.tokenId]={minted:data.status,res:data} 
        //     console.log("ii-data",i)
        //     console.log("ii-data",data)
        //     setFinaTokens(finaTokens)
        // })
        // setFinaTokens(finaTokens);
      // }  

      loadEachToken(parseInt(startId),()=>{
        alert("done");
      })
      
    }
  }


  let loadEachToken = (i,callback)=>{
        console.log("i",i);
        finaTokens[i]= {minted:null,res:null}
        props.chkTokenId(i,allApiCall,(data)=>{
            finaTokens[data.tokenId]={minted:data.status,res:data} 
            console.log("ii-data",i)
            console.log("ii-data",data)
            setFinaTokens(finaTokens);


            if(i <=parseInt(endId)){
              loadEachToken(i+1,callback);
            }else{
              callback();
            }

        })
        setFinaTokens(finaTokens);

  }
  let data =[]
  for (var key in finaTokens) {
    data.push(<div><h6>{key}</h6> <br/> <div>{JSON.stringify(finaTokens[key])}</div></div>)
}

  return (
    <>  
      <div className="row">
        <div className="col-md-6 pt-3 mt-5 text-center">
         Start AppId: <input type="text" value={startId} onChange={e=>setStartId(e.target.value)} />   
         End AppId: <input type="text" value={endId} onChange={e=>setEndId(e.target.value)} />  
         API Call: <input type="checkbox" checked={allApiCall} onChange={()=>{setAllApiCall(!allApiCall)}}/>
         <button onClick={loadTokens}>LookUp - Update</button>
        </div>

        <div>
            {JSON.stringify(finaTokens)}
            {data} 
        </div>
      </div>
    </>
  );
};

// @TODO future update review
const structuredSelector = createStructuredSelector({
    user: (state) => state.user,
  });
const mapDisactcProps = { chkTokenId,checkLogin };
export default connect(structuredSelector, mapDisactcProps)(ApeCheck);


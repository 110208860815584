import { combineReducers } from 'redux';

import { apeGalleryReducer,allApeGalleryReducer,apeNFTReducer,allNFTGalleryReducer,apeS1GalleryReducer,allS1ApeGalleryReducer,buildApeReducer,getPGFKbalanceReducer,getAPESupplyReducer, getAppSound,allBreedApeGalleryReducer} from './list';
import { userReducer,voucherCreditsReducer,apePriceReducer} from './connector';

const rootReducer = combineReducers({
    apeNfts: apeNFTReducer,
    allApeNfts: allNFTGalleryReducer,
    apes: apeGalleryReducer,
    breedApes: allBreedApeGalleryReducer,
    allApes: allApeGalleryReducer,
    s1Apes: apeS1GalleryReducer,
    s1AllApes: allS1ApeGalleryReducer,
    buildApe: buildApeReducer,
    user: userReducer,
    PGFKBalance: getPGFKbalanceReducer,
    APESupply: getAPESupplyReducer,
    appSound: getAppSound,
    voucherCredits: voucherCreditsReducer,
    apePrice: apePriceReducer,
});

export default rootReducer;
import React, { useState,useRef } from "react";
import {Link} from 'react-router-dom';

import {baseImageApe} from "../../utlis"

import * as constants from "../../constants";

import ApeMeter from "../StatsPanel/ApeMeter"
const BodyPart =(props)=> {
  var stars = [];
  for (var i = 0; i < props.star; i++) {
      stars.push(<img
        src="/assets/images/prize-star-icon.png"
        className="img-fluid"
        key={i}
        width="15"
      />);
  }

  return (
    <div className="maker_part">
      <h4 className="part_top_title">{props.title}</h4>
      <div className="pt-2">
      {stars}
      </div>
      <div class="empty_space_bg"><label className="circle_lable pt-0">{props.part}</label></div>
    </div>
  );
};

const ApeBlock = (props) => {
  const {ape,build,currentApeContract,blockType} = props;
  const [isOpened, setIsOpened] = useState(true);
  const myRef = React.createRef();

  function toggleStats() {
    document.getElementById('flip_area').style.display='none';
    setIsOpened(wasOpened => !wasOpened);
    if (myRef.current.style.transform == 'rotateY(180deg)'){
      myRef.current.style.transform = 'rotateY(0deg)';
      setTimeout(()=>{
        document.getElementById('flip_name').innerHTML='Stats';
        document.getElementById('body_parts').style.display='flex';
        document.getElementById('flip_area').style.display='block';
        document.getElementById('apeFrills').style.display='block';
      },200)
    } else {
      myRef.current.style.transform = 'rotateY(180deg)';
      setTimeout(()=>{
        document.getElementById('flip_name').innerHTML='Ape';
        document.getElementById('body_parts').style.display='none';
        document.getElementById('flip_area').style.display='block';
        document.getElementById('apeFrills').style.display='none';
      },200)
     
    }
    
  }

  let part_name = (name) =>{
    switch(name){
      case 'chadium12': return 'Chadium12';
      default: return name;
    }
  }


  let getAttrVal = (key)=>{
    let obj = ape.attributes.find((v)=>v.trait_type == key)
    if(obj){
      return part_name(obj.value)
    }else{
      return "N/A"
    }
  }

  let countStar = (key)=>{
    let value = getAttrVal(key);
    let obj = ape.attributes.filter((v)=>v.value == value)
    return obj.length;
  }

  let countStarRarity = (value)=>{
    let obj = ape.attributes.filter((v)=>v.value == value)
    return obj.length;
  }

  
  let starsCount = [countStar('Grass'),countStar('Metaltoy'),countStar('Space'),countStar('Whitefur'),countStar('Wool'),countStar('Chadium12'),countStar('Chimp'),countStar('Gold'),countStar('Liberty'),countStar('Nebula'),countStar('Orang'),countStar('Rich'),countStar('Wood'),countStarRarity('Corduroy'),countStarRarity('Disco'),countStarRarity('Flowers'),countStarRarity('Lavalamp'),countStarRarity('Sequins'),countStarRarity('Tiedye')].sort().reverse()[0]   

  console.log("starsCount",[countStar('Grass'),countStar('Metaltoy'),countStar('Space'),countStar('Whitefur'),countStar('Wool'),countStar('Chadium12'),countStar('Chimp'),countStar('Gold'),countStar('Liberty'),countStar('Nebula'),countStar('Orang'),countStar('Rich'),countStar('Wood'),countStarRarity('Corduroy'),countStarRarity('Disco'),countStarRarity('Flowers'),countStarRarity('Lavalamp'),countStarRarity('Sequins'),countStarRarity('Tiedye')]);
  console.log("starsCount",starsCount);
   

  const getRarity = () =>{
    switch(starsCount){
      case 6 : return 'Pure';break;
      case 5 : return 'Mythic';break;
      case 4 : return 'Legend';break;
      case 3 : return 'Rare';break;
      case 2 : return 'Common';break;
      case 1 : return 'Common';break;
    }
    return 'Common';
  }

  const shareTwitter = (app)=>{
    let name = constants.APE_TOKEN_SEASON1_ADDR == currentApeContract ? "s1" : "s2";
    if(constants.APE_TOKEN_SEASON3_ADDR == currentApeContract){
      name='s3';
    }
    if(constants.APE_BREED_ADDR == currentApeContract){
      name='mate';
    }

    window.open("https://twitter.com/share?text=Check out my new friend on Ape Island! Where fun meets NFTs and DeFi.&url="+constants.DOMAIN_NAME+"gallery/"+name+"/"+app.id+"&hashtags=ApeIn,ApeIsland");
    constants.GPageEvent("Social","Twitter-Share","Share");
  }

 

  return (
    <>
      <div className="apeMakerBuiltMain">
       
        <button className="btn btn-theme-primary px-3 font-18 flip_area" id="flip_area" onClick={()=>{toggleStats();}} style={{cursor: 'pointer'}}>
          <span id="flip_name">Stats</span> <i className="fa fa-sync"></i>
        </button>
        <div className="apeFlipBox" ref={myRef}>
        {blockType =='sold' &&
            <div className="apeFrills" id="apeFrills">
              <img src="/assets/new_images/post-gen-frills.png" className="img-fluid" />
            </div>
          }
          <div className="apeGeneralView">
            <div className="text-center">
              <br/>
              <br/>
              <img src={ape.image} className="img-fluid" style={{maxWidth:(ape.image==baseImageApe())? "500px":"400px"}}/>
            </div>
            {build &&
              <div className="text-center py-3">
                <button className="btn btn-previous me-1 font-18" onClick={build}>Generate Again</button>

                <Link  to={{
                    pathname: `/ape_buy`, 
                    query:{ape:ape,currentApeContract:currentApeContract}
                  }} className="btn btn-theme-primary px-3 font-18">Buy This Ape</Link>
              </div>
            }

        {blockType =='gallery' &&
              <div className="text-center py-3">
                <Link to="/mating" className="btn btn-previous me-1 font-18" style={{fontSize: '20px', lineHeight: '60px'}}>Ape Mating</Link>
                <button className="btn btn-theme-primary px-3 font-18" onClick={()=>{shareTwitter(ape)}} style={{width:"175px", paddingTop:"5px"}}>Share</button>
                <br/>
              </div>
            }

            {(blockType =='sell' || blockType =='sold') &&
            <div className="text-center py-3">
            </div>
            }
  
            <div className="bodyDetailPart">
              <div className="row justify-content-center" id="body_parts">
                <div className="col-md-3 pt-2">
                  <BodyPart title="HEAD" part={getAttrVal('Head')} star={countStar('Head')}/>
                </div>
                <div className="col-md-3 pt-2">
                  <BodyPart title="LEFT ARM" part={getAttrVal('Left Arm')} star={countStar('Left Arm')}/>
                </div>
                <div className="col-md-3 pt-2">
                 <BodyPart title="RIGHT ARM" part={getAttrVal('Right Arm')}  star={countStar('Right Arm')}/>
                </div>
                <div className="clearfix"></div>
                <div className="col-md-3 pt-2">
                 <BodyPart title="TORSO" part={getAttrVal('Torso')}  star={countStar('Torso')}/>
                </div>
                <div className="col-md-3 pt-2">
                  <BodyPart title="LEFT LEG" part={getAttrVal('Left Leg')} star={countStar('Left Leg')}/>
                </div>
                <div className="col-md-3 pt-2">
                  <BodyPart title="RIGHT LEG" part={getAttrVal('Right Leg')}  star={countStar('Right Leg')}/>
                </div>
              </div>
            </div>

            {(blockType =='sell' || blockType =='sold') &&
            <div className="text-center py-3">
              <br/>&nbsp;
            </div>
            }
            <div className="pt-3">
              <div className="generate_green_sticker pt-3">
                <div className="text-center">
                  <div className="count_box" style={{fontSize: '14px'}}>{getRarity()}</div>
                  <h4 className="heading-2">{ape.name}</h4>
                </div>
              </div>
            </div>
          </div>
          
          <div className="apeDetailedView">
            <div className="app_maker_detailed_stats">
              <ul>
                <li>
                  <div className="item_details">
                    <i className="fas fa-sword"></i> Attack <label>{getAttrVal('Attack')}</label>
                  </div>
                </li>
                <li>
                  <div className="item_details">
                    <i className="fas fa-shield"></i> Defense <label>{getAttrVal('Defense')}</label>
                  </div>
                </li>
                <li>
                  <div className="item_details">
                    <i className="fas fa-bolt"></i> Speed <label>{getAttrVal('Speed')}</label>
                  </div>
                </li>
                <li>
                  <div className="item_details">
                    <i className="fas fa-magic"></i> Intellect <label>{getAttrVal('Intellect')}</label>
                  </div>
                </li>
                
                <li>
                  <div className="item_details">
                    <i className="fas fa-magic"></i> Ability <label>{getAttrVal('Ability')}</label>
                  </div>
                </li>

                <li>
                  <div className="item_details">
                    <i className="fas fa-monkey"></i> Nature <label>{getAttrVal('Nature')}</label>
                  </div>
                </li>
                <li style={{height: '55px'}}></li>
                <li style={{height: '55px'}}></li>
              </ul>
              <div className="ape_meter animate__animated animate__pulse animate__repeat-2 w-100 text-center" style={{position: 'absolute', bottom: '-0.5rem'}}>
                 <ApeMeter value={starsCount} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApeBlock;

import React,{useEffect} from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import * as constants from "../../constants"

import {Link,withRouter} from 'react-router-dom';
import ApePart from "./ApePart";
import ApeBlock from  "./ApeBlock";

import {baseImageApe} from "../../utlis";
import { findUserApe } from '../../actions';

const ApeBody = (props) => {
  let {ape,buildApeObject,currentApeContract} = props;

  let interVal = null;
  let find_image =(picture)=>{
    console.log("picture",picture);
    console.log("baseImageApe()",baseImageApe());
    if(picture.includes(baseImageApe())){
      props.findUserApe(props.user.user.eth_address,ape.id,currentApeContract)
    }else{
      if(interVal!=null){
        clearInterval(interVal);
        interVal = null;
      }
    }
  }
 
  if(ape){  
    useEffect(()=>{
        interVal = setInterval(()=>{
          find_image(ape.image);
        },10000);
        return ()=>{
          clearInterval(interVal);
        }
    },[ape.image]);
  }

  return (
    <>
      {ape? 
        <ApeBlock build={props.build} ape={ape} currentApeContract={currentApeContract} blockType={props.build ? "build":props.blockType}></ApeBlock>
      :
      <div className="apeMakerMain py-5">
        <div className="text-center">
          <img src="/assets/images/character.gif" className="img-fluid" width="500"/>
        </div>
        <div className="bodyDetailPart">
          <div className="row justify-content-center">
            <div className="col-md-3 pt-2">
              <div className="maker_part">
                <h4 className="part_top_title">HEAD</h4>
                <div><img src="/assets/new_images/apein_char.png" className="mt-3" /></div>
                <div className="empty_space_bg">---</div>
              </div>
            </div>
            <div className="col-md-3 pt-2">
              <div className="maker_part">
                <h4 className="part_top_title">LEFT ARM</h4>
                <div><img src="/assets/new_images/apein_char.png" className="mt-3" /></div>
                <div className="empty_space_bg">---</div>
              </div>
            </div>
            <div className="col-md-3 pt-2">
              <div className="maker_part">
                <h4 className="part_top_title">RIGHT ARM</h4>
                <div><img src="/assets/new_images/apein_char.png" className="mt-3" /></div>
                <div className="empty_space_bg">---</div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="col-md-3 pt-2">
              <div className="maker_part">
                <h4 className="part_top_title">TORSO</h4>
                <div><img src="/assets/new_images/apein_char.png" className="mt-3" /></div>
                <div className="empty_space_bg">---</div>
              </div>
            </div>
            <div className="col-md-3 pt-2">
              <div className="maker_part">
                <h4 className="part_top_title">LEFT LEG</h4>
                <div><img src="/assets/new_images/apein_char.png" className="mt-3" /></div>
                <div className="empty_space_bg">---</div>
              </div>
            </div>
            <div className="col-md-3 pt-2">
              <div className="maker_part">
                <h4 className="part_top_title">RIGHT LEG</h4>
                <div><img src="/assets/new_images/apein_char.png" className="mt-3" /></div>
                <div className="empty_space_bg">---</div>
              </div>
            </div>
          </div>
        </div>
        {!props.blockType ? 
        <>
        <div className="pt-3">
          <div className="balance_indc">
            <div className="text-center">{constants.IMG_GENERATE_CREDIT} {constants.IMG_GENERATE_CREDIT > 1 ? "Credits" : "Credit"}</div>
          </div>
        </div>

        <div className="pt-2">
        {(buildApeObject.type!='request')? 
          <div className="generate_apein_button" style={{cursor:"pointer"}} onClick={props.build}>
            <div className="text-center" style={{marginTop:"7px", fontSize: '1.35rem', lineHeight: '1.25'}}>Generate</div>
          </div>
          :
          <div className="generate_apein_button" style={{cursor:"pointer"}}>
          <div className="text-center" style={{marginTop:"7px", fontSize: '1.35rem', lineHeight: '1.25'}}>Generating...</div>
        </div>
        }
        </div>
        </>
        :
        <div className="pt-3">
          <div className="balance_indc">
            <div className="text-center">Choose Ape</div>
          </div>
        </div>
        
      }
      </div>
      }

       
    </>
  );
};


// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
  apes: (state) => state.apes,
});
const mapDisactcProps = {findUserApe};
export default connect(structuredSelector, mapDisactcProps)(ApeBody);


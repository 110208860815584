import React from 'react'

import {APE_TOKEN_SEASON1_ADDR,APE_TOKEN_SEASON2_ADDR} from "../../../constants/index"

import Modal from 'react-bootstrap/Modal'
const ChooseSeason = (props) => {


  return (
    <>
      <Modal show={props.show} onHide={props.handleCloseSeasonModal} backdrop={true} backdropClassName="modal-backdrop foo-modal-backdrop in" dialogClassName="modal-choose-season ">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="heading-3 mb-0 text-center" style={{ fontSize: "30px" }}>Choose Season</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-5">
          <div className="d-block my-4">
            <button className="btn w-100  btn-previous me-1 font-18" onClick={() => { props.handleShow(APE_TOKEN_SEASON1_ADDR) }}> Season 1</button>
          </div>
          <div className="d-block my-4">
            <button className="btn w-100  btn-previous me-1 font-18" onClick={() => { props.handleShow(APE_TOKEN_SEASON2_ADDR) }}> Season 2</button>
          </div>

        </Modal.Body>

      </Modal>
    </>
  )
}

export default ChooseSeason

import React,{useEffect,useState} from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { metamaskLogin, checkLogin ,loginReset} from '../../actions';
import Navbar from "../../components/NavBar";
import * as constants from "../../constants";

import MetaMaskAlert from "../../components/Alert/MetaMask"
import DangerAlert from "../../components/Alert/DangerAlert"

import * as contents from "../../constants"

const LoginOld = (props) => {
  const {user} = props;
  const [errorPopup, setErrorPopup] = useState(!user.network);

  const chkNetowrkError = ()=>{
    setErrorPopup(!user.network)
  }

  
  useEffect(()=>{
    if(user.login){
      props.history.push("/how_to_play");
    }else{
      chkNetowrkError();
      props.checkLogin();
    }
  },[user.login]);

  useEffect(()=>{
    chkNetowrkError();
  },[user.network]);

  const  metamaskLoginBtn = ()=>{
    props.loginReset();
    // if(user.network){ 
      if (typeof window.ethereum !== 'undefined') {
        props.metamaskLogin();
      }else{
        window.open("https://metamask.app.link/dapp/"+contents.DOMAIN_NAME.replace("https://", "").replace("http://", "")+"/dashboard?login=metamask");
      } 
    // }else{
    //   chkNetowrkError();
    // }
  }



  return (
    <div className="container">
      {errorPopup &&
        <div style={{position:'fixed',top:'0',left:'0',width:'100%',height:'100%',background:"#00000075",zIndex:"100"}} onClick={(e)=>{if(e.target.style.position =='fixed'){setErrorPopup(false)}}}>
          <div style={{width:"640px",height:"auto",margin:"0 auto", padding:"10px",position:"relative",marginTop:"15%"}}>
          <DangerAlert title="Ethereum Network Notice
            " description={contents.ETH_ENV =='development' ? "You need to be connected to Ethereum Rinkeby Network to play on this parallel universe of Ape Island. Please change your network settings to continue." : "You need to be connected to Ethereum Mainnet to play on Ape Island. Please change your network settings to continue."} full={true}/>
          </div>
        </div>
      }

      <div className="row justify-content-center">

        <div className="col-md-6">
          
          <div className="login_on_boarding">
            <div className="ape_top_banner text-center">
              <div className="row justify-content-center mt-3">
                <div className="col-md-8 mt-5">
                  <h1 className=" logo">Ahoy, Matey</h1>
                  <h5 style={{fontSize: '26px', color: '#4B370D'}}>You need to connect a web3 wallet in order to continue.</h5>
                  
                  <button className="btn btn-connect  mt-2 font-18" onClick={()=>{metamaskLoginBtn()}} >Connect Metamask</button>
                  <br/>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"><div className="py-5"></div></div>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h2 className="version mt-5 px-3 text-center">
                <img src="/assets/images/info-icon.png" /> No Wallet Detected
              </h2>
            </div>
          </div>
        </div>
        
        
      </div>
    </div>
  );
};

// @TODO future update review
const structuredSelector = createStructuredSelector({
  user: (state) => state.user,
});
const mapDisactcProps = {metamaskLogin, checkLogin,loginReset};
export default connect(structuredSelector, mapDisactcProps)(LoginOld);
